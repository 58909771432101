import React, { createContext, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export const OptionsContext = createContext()

export const OptionsProvider = ({ children }) => {
    const optionsData = useStaticQuery(graphql`
        {
            wp {
                generalSettings {
                    title
                    description
                }
            }
            allWp {
                nodes {
                    id
                    siteOptions {
                        acfOptionsPage {
                            siteInfo {
                                addInfoStrip
                                infoStripType
                                infoStripXmasIcon {
                                    id
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(width: 60)
                                        }
                                    }
                                    altText
                                    title
                                }
                                infoStripXmasContent
                                infoStripEasterIcon {
                                    id
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(width: 80)
                                        }
                                    }
                                    altText
                                    title
                                }
                                infoStripEasterContent
                                infoStripBholIcon {
                                    id
                                    localFile {
                                        childImageSharp {
                                            gatsbyImageData(width: 80)
                                        }
                                    }
                                    altText
                                    title
                                }
                                infoStripBholContent
                            }
                            companyDetails {
                                address
                                telephoneNumber
                                officeHours {
                                    dayRange
                                    times
                                }
                            }
                            helpCentre {
                                postTypeName
                                postTypeCategoryKey
                                title
                                relativeUrl
                                heroEyebrow
                                heroTitle
                                articleDisclaimer
                            }
                            footer {
                                column1 {
                                    title
                                    linksRepeater {
                                        link {
                                            target
                                            title
                                            url
                                        }
                                    }
                                }
                                column2 {
                                    title
                                    linksRepeater {
                                        link {
                                            target
                                            title
                                            url
                                        }
                                    }
                                }
                                column3 {
                                    title
                                    content
                                }
                                copyright
                                copyrightReduced
                            }
                            globalDisclaimer
                            journey {
                                mainJourney {
                                    ... on WpPage {
                                        link
                                    }
                                }
                                journeyDisclaimer
                            }
                            effects {
                                addSnow
                            }
                        }
                    }
                }
            }
        }
    `)

    const [options, setOptions] = useState({
        siteData: optionsData.wp.generalSettings,
        ...optionsData.allWp.nodes[0]?.siteOptions.acfOptionsPage
    })

    return <OptionsContext.Provider value={[options, setOptions]}>{children}</OptionsContext.Provider>
}
