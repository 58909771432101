// This function refrieves all the added parameters to the existing URL
const getUrlParams = () => {
    const queryString = window.location.search

    const urlParams = new URLSearchParams(queryString)

    return urlParams
}

// This function allows you to extract a certain URL param
export const getSelectedUrlParam = (paramKey, stateSetter) => {
    const urlParams = getUrlParams()

    return getParamValue(urlParams, paramKey, stateSetter)
}

// This function turns the existing URL params into a string
const getExistingUrlParams = () => {
    const urlParams = getUrlParams()

    const allUrlParams = urlParams.toString()

    return allUrlParams
}

// This function will set a state with a single URL param
const getParamValue = (urlParams, paramKey, stateSetter) => {
    const paramValue = urlParams.get(paramKey) || ''

    return stateSetter(paramValue)
}

// This function will generate a list of param key and value pairs
const generateListOfParams = (params) => {
    const paramArray = []

    params.utmsList?.map((node) =>
        paramArray.push({
            key: node.key,
            value: node.value
        })
    )

    const paramList = paramArray.map((node) => {
        return `${node.key}=${node.value}`
    })

    return paramList
}

// This function will create a string of all UTMs including UTMs manually added to a link and UTMs added to all links per page (used for top level post)
export const getAllParams = (addedParams) => {
    const existingParams = getExistingUrlParams()

    const paramList = generateListOfParams(addedParams)

    const params = `${
        paramList.length !== 0 || existingParams ? '?' : ''
    }${paramList.join('&')}${
        paramList.length !== 0 && existingParams ? '&' : ''
    }${existingParams && `${existingParams}`}`

    return params
}

// This function will create a string of all UTMs including UTMs manually added to a URL, UTMs added to all links per page, and unique UTM added to a button
export const generateUniqueButtonParams = (addedParams, buttonParams) => {
    const buttonParamsExist = buttonParams.addUtms
    const paramList = generateListOfParams(buttonParams)

    const params = `${addedParams ? addedParams : ''}${
        buttonParamsExist ? '&' : ''
    }${paramList.join('&')}`

    return params
}
