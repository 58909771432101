import React, { createContext, useState, useEffect } from 'react'
import { getSelectedUrlParam } from '/src/functions/paramTools'

export const UtmContext = createContext()

export const UtmProvider = ({ children }) => {
    const [officeLineUtms, setOfficeLineUtms] = useState({})

    const [utmCampaign, setUtmCampaign] = useState('')
    const [subSubSource, setSubSubSource] = useState('')

    useEffect(() => {
        getSelectedUrlParam('utm_campaign', setUtmCampaign)
        getSelectedUrlParam('sub_sub_source', setSubSubSource)
    }, []) // Grab values from the URL parameters

    useEffect(() => {
        setOfficeLineUtms({
            utmCampaign: utmCampaign,
            subSubSource: subSubSource
        })
    }, [utmCampaign, subSubSource])

    return <UtmContext.Provider value={[officeLineUtms, setOfficeLineUtms]}>{children}</UtmContext.Provider>
}
