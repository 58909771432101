import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { OptionsProvider } from '/src/contexts/OptionsContext'
import { UserProvider } from '/src/contexts/UserContext'
import { UtmProvider } from '/src/contexts/UtmContext'

const Layout = ({ children }) => {
    useEffect(() => {
        const htmlTag = document.querySelector('html')
        htmlTag.classList.add('has-js')
    }, []) // Apply JS Class to HTML tag

    return (
        <OptionsProvider>
            <UserProvider>
                <UtmProvider>{children}</UtmProvider>
            </UserProvider>
        </OptionsProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
