import React, { createContext, useState, useEffect } from 'react'
import { getSelectedUrlParam } from '/src/functions/paramTools'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({})

    const [plid, setPlid] = useState('')
    const [plidCaps, setPlidCaps] = useState('')
    const [plidTitle, setPlidTitle] = useState('')
    const [lid, setLid] = useState('')
    const [lidCaps, setLidCaps] = useState('')
    const [lidTitle, setLidTitle] = useState('')
    const [name, setName] = useState('')

    useEffect(() => {
        getSelectedUrlParam('plid', setPlid)
        getSelectedUrlParam('PLID', setPlidCaps) // Also pick up this value in caps, if it is passed across in this way
        getSelectedUrlParam('Plid', setPlidTitle) // Also pick up this value in title case, if it is passed across in this way
        getSelectedUrlParam('lid', setLid)
        getSelectedUrlParam('LID', setLidCaps) // Also pick up this value in caps, if it is passed across in this way
        getSelectedUrlParam('Lid', setLidTitle) // Also pick up this value in title case, if it is passed across in this way
        getSelectedUrlParam('name', setName)
    }, []) // Grab values from the URL parameters

    useEffect(() => {
        setUser({
            plid: plid || plidCaps || plidTitle, // OL user ref
            lid: lid || lidCaps || lidTitle, // OL ref received immediately when submitting a lead
            name: name
        })
    }, [plid, plidCaps, plidTitle, lid, lidCaps, lidTitle, name])

    return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>
}
